import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'components/ui/link';
import Img from 'gatsby-image';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import arrow from 'img/ui/arrow_white.svg';
import Banner from 'components/usability-testing/banner';

const UsabilityTestingQuestions = () => {
  const { artboard } = useStaticQuery(graphql`
    query {
      artboard: file(relativePath: { eq: "usability-testing/usability-questions/artboard.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <h2>Why is it crucial to select the right usability testing questions?</h2>
      <p>
        The results of your usability test will highly depend on your question selection. Here are a
        few reasons why it’s necessary to choose the right user testing questions:
      </p>
      <ul>
        <li>To get an overall understanding of how users feel or perceive your app/website</li>
        <li>
          To identify issues or features that are troublesome (or such that are total blockers and
          need to be fixed critically)
        </li>
        <li>To understand customers fears, goals, and challenges </li>
        <li>To find out why users abandon tasks (for ex. cart abandonment)</li>
        <li>To design better workflows that will help users achieve their goals faster.</li>
      </ul>
      <Img
        fluid={artboard.childImageSharp.fluid}
        alt="Design"
        title="Usability testing questions for enhancing UX"
      />
      <h2>Usability testing questions for enhancing UX</h2>
      <p>
        We’ve split the questions into four categories: screening, pre-test, in-test, and post-test
        usability testing questions to make question selection easier.
      </p>
      <h3>Screening usability questions</h3>
      <p>
        Screening usability questions will help you determine what type of users to involve in your
        UX testing process. It’s good practice to identify and list all the criteria that an ideal
        participant should meet.{' '}
      </p>
      <ol>
        <li>
          <p>
            <strong>Demographic details questions</strong> – asking for someone’s age, gender,
            education, and occupation (or, in the case of businesses, inquiring about their
            firmographics, like company size, industry, etc.). This information is important for
            ensuring that the right users are testing your product. For example, if you target
            marketers and you have CSM’s among your participants, it will skew your results.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>Tech-literacy questions</strong> – find out how often they use technologies and
            devices, how easy or difficult using these devices is for them. If you’re testing a
            feature or a product that requires high tech proficiency then you can’t have laggards
            among your testers.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>How do you currently solve the problems this product seeks to solve?</strong> –
            are users also employing any competing solutions? Perhaps, they might be using a method
            that is much more complex and time-consuming than what you’re offering. Such insights
            will let you understand how to better reach target users and convince them to use your
            solution.
          </p>
        </li>
        <li>
          <p>
            <strong>
              What’s your income (in case of B2C) or what’s your budget (in case of B2B solutions)?
            </strong>{' '}
            – essential for paid functions in apps, e-commerce, and premium plans. It also lets you
            establish who your High-Value Customers are and focus on delivering them the perfect
            user experience.{' '}
          </p>
          <h3>Pre-test usability questions </h3>
          <p>
            Pre-test usability questions are used to further narrow down your participants, making
            sure only the most suitable ones take part in your usability test.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>Behavioral questions</strong> – ask: how frequently do you engage with the
            product (or similar products). If you want to specifically focus on power users or
            disengaged users, then asking this question will help you define their usage frequency.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>What do you use the website or app for?</strong> – if you want to focus on
            specific product use cases, this user testing question will help you eliminate all users
            who fail to meet this criterion.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>How satisfied are you with the available workflows?</strong> – while designing
            apps or websites we always have specific workflows in mind, which we think are easy to
            follow and intuitive. Use this question to discover whether users agree with your way of
            thinking.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>Which features do you use most?</strong> – if you want to build your test around
            a specific feature, then this question is necessary to identify the users who are
            already familiar with it. If you end up with participants who have never used the
            feature then they won’t bring any valuable insights into the test.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>What problems/tasks does the product/website/app help you solve?</strong> –
            products are built to solve specific problems. Asking this usability survey question
            will help you verify your hypotheses and confirm whether your product actually solves
            the problems it was designed to solve. It might also reveal new product usages which you
            were not aware of.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>What other apps did you use or research prior to selecting this app?</strong> –
            answers to this usability testing question will help you check which competitors you
            fought against. As well as verify if new players appeared on the horizon, how you
            compare to them, and what you can do to outperform them.{' '}
          </p>
          <h3>In-test usability questions </h3>
          <p>
            These types of questions are asked while your users engage with your website or app. By
            asking in-test usability questions, you can understand how they use features, whether
            they find the interface clear, and why/when they decide to abandon a process, among many
            others.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>
              When you log on, what’s the first thing you do? Is there another way to complete this
              task?
            </strong>{' '}
            – asking such a question will let you in on user motivation and the different paths they
            use to reach the desired result.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>How do you use this feature?</strong> – while the question might seem very
            general, it’s worth asking to learn about any non-standard use practices. For instance,
            thanks to a survey conducted among users, a company called TechJury found out that their
            servers were being used not for business purposes, but by{' '}
            <a
              href="https://survicate.com/customer-feedback/business-strategy/"
              taeget="_blank"
              rel="noopener noreferrer nofollow"
            >
              a gamer community
            </a>
            . Such revelations can help you reinvent your products or features to better cater to
            your users.
          </p>
        </li>
        <li>
          <p>
            Do you like the interface? Is it easy to use? – a simple question that will let you in
            on user sentiment and point to any potential UX or UI flaws.
          </p>
        </li>
        <li>
          <p>
            What do you think about how information and features are laid out? – this usability
            testing question will help you understand if anything – from the way a product is
            designed, up to the way the UX message is written – might be a source of confusion.
          </p>
        </li>
        <li>
          <p>
            <strong>Exit intent</strong> – if the user wants to go back to the previous page or
            leave the app or website altogether, ask them for the reason (for instance, the
            interface confuses them, errors or bugs appeared, etc.).
          </p>
          <h3>Post-testing usability questions</h3>
          <p>
            After the users have completed the usability test, it’s time to ask them about their
            impressions. In the post-testing phase, make sure to ask about their general experiences
            with your app or website, which features they liked and disliked, and whether they’d
            recommend your product to others. These and the other questions we display below will
            arm you in a whole range of invaluable insights.
          </p>
        </li>
        <li>
          <p>
            <strong>Overall, what’s your experience been with the website or app?</strong> – Asking
            this question in the post-testing phase will let you collect live impressions from
            users. They might be inclined to name any confusing or underperforming areas that have
            stuck with them, or will give you a general opinion on the user experience. Depending on
            your preference and how you’ll be analyzing the insights, you might ask them to give you
            a score on a numerical scale or ask them for a descriptive, text answer only.
          </p>
        </li>
        <li>
          <p>
            <strong>What did you like about the product?</strong> – knowing what features users
            appreciate will help you in your future development decisions. You’ll know which
            features or benefits to focus your marketing efforts on.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>What didn’t you like about the product?</strong> – this info will make you aware
            of the product areas which require further work. Maybe there are features that you can
            get it rid of completely to make the product easier to use, and as a result, enhance the
            user experience.
          </p>
        </li>
        <li>
          <p>
            <strong>Is there any product similar to this one?</strong> – when you ask this question,
            you’ll learn whom you’re competing for your users’ attention with. These insights will
            not only fuel your product strategy (i.e. how to outpace your competitors through
            creating better user experiences) but will also let you improve your marketing
            messaging, pricing strategy, and more.
          </p>
        </li>
        <li>
          <p>
            <strong>What were your feelings about the instructions?</strong> – after your testers
            have completed the task, it’s worth asking them whether the instructions were clear. If
            they weren’t, you’ll understand if the reason for this was a confusing user workflow,
            interface, or perhaps the instructions could have been written in a better way.
          </p>
        </li>
        <li>
          <p>
            <strong>Do you know anyone that would like this product?</strong> – this question is a
            goldmine when it comes to building out your user profiles. Ask your respondents to
            describe the people they think your product would be right for. For many of us, it’s
            easier to talk about people and what goals, challenges, and fears they have than to talk
            about products.
          </p>
        </li>
        <li>
          <p>
            <strong>Customer Effort Score (CES)</strong> – this usability survey question is a
            popular metric measured by product teams. Asked after a completed task, it lets you
            collect quantitative data on how difficult or easy it was to perform the task users have
            just finished, and nail down improvement areas.
          </p>
        </li>
        <li>
          <p>
            <strong>Net Promoter Score (NPS)</strong> – NPS is arguably the most popular customer
            satisfaction metric in the world. It lets you ask your users: “How likely are you to
            recommend this website or app to others?” on a scale from 0 to 10, and ask them for the
            reasons behind their answer. While the NPS score, per se, is a metric used to track
            overall customer loyalty levels, it&apos;s invaluable for UX teams. Reading the low
            scored responses (i.e. between 0 and 6) along with the commentary will help you capture
            any recurring usability issues.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Given your experience, how frequently would you use this product/feature?
            </strong>{' '}
            – After your respondents have used a certain feature or product, they’ll likely be able
            to evaluate how often they’d need (or want) it. By asking this question, you’ll find out
            what impression the product/feature has made, and how important it is in helping users
            achieve their goals.{' '}
          </p>
        </li>
      </ol>
      <Banner />
      <h2>Summary</h2>
      <p>
        As we’ve shown in the examples above, there are plenty of user testing questions worth
        asking when you conduct{' '}
        <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
          usability testing
        </Link>
        . In the screening phase, we recommend that you ask your users for basic information, such
        as their demographics or firmographics to build accurate user profiles. This should be
        followed by behavioral questions asked in the pre-test stage.{' '}
      </p>
      <p>
        During the test, it’s important that you collect insights on your product, interface, or
        specific user paths. Last, but not least, after your users have completed the task, you
        should ask for their general impressions to learn how you can improve the user experience.
        All these insights will help you optimize your product and ensure you’re a relevant service
        for your users.
      </p>
      <p>All that’s left to say? Good luck!</p>
      <div className="next-chapter">
        <Link href="/usability-testing/usability-reporting/">
          <div>
            <p>Chapter 3</p>
            <p>How to Become Great at Usability Reporting</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(UsabilityTestingQuestions);
